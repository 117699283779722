import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch,HashRouter } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
//import GlobalProvider from "./components/context" ;


const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./login/Login'));
const Register = React.lazy(() => import('./login/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      nama_respon:'',
      login:false
    } 
  }


  /*componentDidMount() {
    this._bootstrapAsync();
  }

  _bootstrapAsync = async () => {
    const userToken =  localStorage.getItem('userToken');
    if (userToken) {
      //this.props.dispatch({type : 'LOGOUT',value : true})
      this.setState({login:true}) ;
    }
    this.setState({isLoading:false}) ;
  };*/


  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> 
              

              {/*{
                this.state.login ? 
                <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} /> 
                :
                <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
              }
              */}
              
              
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

//export default GlobalProvider(App);
export default App;